import { createStyles, rem } from "@mantine/core";

export const useFooterStyles = createStyles((theme) => ({
  footer: {
    backgroundColor: "#E9ECEF",
    [theme.fn.smallerThan("md")]: {
      backgroundColor: theme.white,
    },
  },
}));
