import { createStyles, rem } from "@mantine/core";

export const useHeaderStyles = createStyles((theme, pinned: boolean) => ({
  header: {
    [theme.fn.largerThan("sm")]: {
      paddingLeft: theme.spacing.md,
      paddingRight: theme.spacing.md,
    },
    paddingLeft: theme.spacing.xs,
    paddingRight: theme.spacing.xs,
    backgroundColor: "#EBEEF1",
    transform: `translate3d(0, ${pinned ? 0 : rem(-60)}, 0)`,
    transition: "transform 400ms ease",
  },

  content: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "71.25rem",
  },

  logo: {
    cursor: "pointer",
  },

  brandText: {
    fontSize: "13px",
    color: "#7A84AD",
    letterSpacing: "3.2px",
  },
}));
