import { createStyles } from "@mantine/core";

export const useContactStyles = createStyles((theme) => ({
  title: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },

    [theme.fn.smallerThan("sm")]: {
      marginBottom: theme.spacing.xl,
    },
  },

  optionIcon: {
    flexShrink: 0,
  },

  wrapper: {
    gap: "15px",
    [theme.fn.smallerThan("md")]: {
      flexDirection: "column",
    },
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },

  map: {
    border: 0,
    height: "450px",
    width: "500px",
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },

  container: {
    backgroundImage:
      "linear-gradient(180deg, rgba(251, 251, 251, 0) 0.05%, #E9ECEF 99.95%)",
    backgroundRepeat: "no-repeat",

    [theme.fn.smallerThan("md")]: {
      backgroundImage: "none",
    },
  },
}));
