import { createStyles } from "@mantine/core";

export const useFeaturesStyles = createStyles((theme) => ({
  card: {
    border: `${1}px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
    backgroundColor: "rgba(255, 255, 255, 1)",
  },

  title: {
    fontSize: 34,
    fontWeight: 900,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 24,
    },
  },

  description: {
    maxWidth: 600,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: "#50549E",
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  container: {
    padding: "1.5rem 1rem 1.5rem 1rem",
    [theme.fn.smallerThan("md")]: {
      padding: "1.5rem 0rem 1.5rem 0rem",
    },
  },
}));
