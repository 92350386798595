import { Card, Container, SimpleGrid, Text, ThemeIcon, Title } from "@mantine/core";
import { Trans, useTranslation } from "react-i18next";
import { PiCallBellFill } from "react-icons/pi";
import { MdPayment, MdReceiptLong, MdOutlineDocumentScanner } from "react-icons/md";
import { TbFridge, TbDoorEnter } from "react-icons/tb";
import { useFeaturesStyles } from "./Features.styles";

const Features = () => {
  const { classes, theme } = useFeaturesStyles();
  const { t } = useTranslation();

  const data = [
    {
      title: t("features.featureOneTitle"),
      description: t("features.featureOneDescription"),
      icon: PiCallBellFill,
      color: "yellow",
      size: 26,
    },
    {
      title: t("features.featureTwoTitle"),
      description: t("features.featureTwoDescription"),
      icon: MdOutlineDocumentScanner,
      color: "blue",
      size: 26,
    },
    {
      title: t("features.featureThreeTitle"),
      description: t("features.featureThreeDescription"),
      icon: TbFridge,
      color: "cyan",
      size: 26,
    },
    {
      title: t("features.featureFourTitle"),
      description: t("features.featureFourDescription"),
      icon: TbDoorEnter,
      color: "indigo",
      size: 26,
    },

    {
      title: t("features.featureFiveTitle"),
      description: t("features.featureFiveDescription"),
      icon: MdPayment,
      color: "teal",
      size: 26,
    },
    {
      title: t("features.featureSixTitle"),
      description: t("features.featureSixDescription"),
      icon: MdReceiptLong,
      color: "red",
      size: 26,
    },
  ];

  const CardProps = {
    shadow: "md",
    radius: "md",
    className: classes.card,
    padding: "xl",
  };

  const features = data.map((feature) => (
    <Card key={feature.title} {...CardProps}>
      <ThemeIcon
        radius="xl"
        size={48}
        color={theme.colors[feature.color][3]}
        style={{ boxShadow: `0 0 0 8px ${theme.colors[feature.color][0]}` }}
      >
        <feature.icon
          size={feature.size}
          color={theme.colors[feature.color][9]}
        />
      </ThemeIcon>
      <Title order={3} fz="lg" fw={500} mt="md">
        {feature.title}
      </Title>

      <Text fz="sm" c="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  const SimpleGridProps = {
    cols: 3,
    spacing: "xl",
    mt: 50,
    breakpoints: [
      { maxWidth: "md", cols: 2 },
      { maxWidth: "sm", cols: 1 },
    ],
  };

  return (
    <Container size="lg" py="xl" mt={30} className={classes.container}>
      <Title order={1} className={classes.title} ta="center" mt="sm">
        <Trans
          i18nKey="landing.title"
          components={{ CustomText: <Text span c="#50549e" inherit /> }}
        />
      </Title>
      <Text c="dimmed" className={classes.description} ta="center" mt="md">
        {t("landing.description")}
      </Text>
      <SimpleGrid {...SimpleGridProps}>
        {features}
      </SimpleGrid>
    </Container>
  );
};

export default Features;
