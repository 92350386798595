import { createStyles } from "@mantine/core";

export const useLayoutStyles = createStyles((theme) => ({
  main: {
    minHeight: "calc(100vh - 60px)",
    padding: "60px 0px 0px 0px",

    backgroundImage:
      "linear-gradient(180deg, #E9ECEF, rgba(255, 255, 255, 0) 40.00%)",
    backgroundRepeat: "no-repeat",

    [theme.fn.smallerThan("md")]: {
      padding: "60px 16px 0px 16px",
    },
  },
}));
