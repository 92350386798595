import { createStyles } from "@mantine/core";

export const useArticleStyles = createStyles((theme) => ({
  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    lineHeight: 1,
  },

  image: {
    maxWidth: "100px",

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  titleContainer: {
    [theme.fn.smallerThan("sm")]: {
      marginRight: 51,
    },
  },

  description: {
    [theme.fn.smallerThan("sm")]: {
      fontSize: 16,
    },
  },

  content: {
    maxWidth: 700,
    gap: "0.25rem",
    alignItems: "flex-start",
    [theme.fn.smallerThan("sm")]: {
      gap: "0.75rem",
      alignItems: "center",
    },
  },

  container: {
    marginTop: "100px",
    [theme.fn.smallerThan("sm")]: {
      marginTop: "80px",
      paddingBottom: "0px",
    },
  },
}));
