import {
  Container,
  Title,
  Text,
  Image,
  Stack,
  Flex,
  Anchor,
} from "@mantine/core";
import { solonH } from "../../assets";
import { TbHomeCog } from "react-icons/tb";
import { useArticleStyles } from "./Article.styles";
import { Trans, useTranslation } from "react-i18next";

const Article = () => {
  const { classes } = useArticleStyles();
  const { t } = useTranslation();

  return (
    <Container size="lg" py="xl" className={classes.container}>
      <Stack justify="center" className={classes.content}>
        <Flex align="center" gap="sm" className={classes.titleContainer}>
          <TbHomeCog size={35} color="#4985E9" />
          <Title order={2} fz={24} className={classes.title}>
            {t("article.pmsIntegrationTitle")}
          </Title>
        </Flex>
        <Flex align="center">
          <Text fw={500} fz="lg" mb={5} className={classes.description}>
            <Trans
              i18nKey="article.pmsIntegrationDescription"
              components={{
                CustomText: (
                  <Anchor href="https://www.multisoft.ro/program/solon-h-hotel" />
                ),
              }}
            />
          </Text>
          <Image src={solonH} className={classes.image} />
        </Flex>
        <Text fz="sm" c="dimmed">
          {t("article.pmsIntegrationDetails")}
        </Text>
      </Stack>
    </Container>
  );
};

export default Article;
