import { Features, Contact, PaymentProcessors, Article } from "../components";

const LandingPage = () => {
  return (
    <>
      <Features />
      <Article />
      <PaymentProcessors />
      <Contact />
    </>
  );
};

export default LandingPage;
